import React from "react"
import { useLocalization } from "gatsby-theme-i18n"
import { useUser } from "@contexts/user"
import { Title, Spacer, Section, Avatar, Button, Loader, Image } from "@bw/bits"
import { IntuitiveDesign, Fees, Customer } from "@bw/icons"
import { TopUsers, ArtistOfTheDay } from "@bw/modules"
import { useTranslation } from "react-i18next"
import { Layout } from "@bw/layouts"
import { Box } from "grommet"
import { Fader, HighlightTeaser, NftTeaser } from "@bw/partials"
import { getLastSoldTokens } from "@actions/nft"
import { useQuery } from "react-query"
import { useNfts } from "@contexts/nft"
import { breakpoints } from "../theme"
import styled from "styled-components"
import { Link, graphql } from "gatsby"
import { toast } from "react-toastify"

const Subtitle = styled.p`
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  @media (max-width: ${breakpoints.medium}px) {
    font-size: 18px;
    line-height: 26px;
  }
`

const StyledTitle = styled.h1`
  font-size: 48px;
  line-height: 55px;
  font-weight: 800;
  color: rgba(96, 128, 233, 1);
  max-width: 500px;
  margin-top: 0;

  @media (max-width: ${breakpoints.medium}px) {
    font-size: 37px;
    line-height: 47px;
  }
`

const StyledLink = styled(Link)`
  font-weight: 800;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #6080e9;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-bottom: 12px;
`

const Advantages = styled.div`
  display: flex;
  gap: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: ${breakpoints.medium}px) {
    flex-direction: column;
    grid-template-columns: 1fr;
  }
`

const GridHero = styled.div`
  display: grid;
  gap: 48px;

  @media (min-width: 800px) {
    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  }

  @media (min-width: ${breakpoints.large}px) {
    gap: ${props => (props.gap ? props.gap : "96px")};
  }
`

const Center = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`

const Grid = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: ${props => (props.column ? props.column : "")};
  justify-content: ${props => (props.column ? "space-around" : "")};

  @media (min-width: ${breakpoints.small}px) {
    gap: 48px;
  }

  @media (min-width: 840px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media (min-width: ${breakpoints.large}px) {
    gap: ${props => (props.gap ? props.gap : "35px")};
  }
`

const IndexPage = ({ data, pageContext }) => {
  const { locale, localizedPath, defaultLang } = useLocalization()
  const { originalPath } = pageContext
  const { t } = useTranslation()
  const [user] = useUser()
  const [nfts] = useNfts()
  const collections = data.allCollection.nodes
  const lastSoldTokensQuery = useQuery(["last_sold"], () => {
    return getLastSoldTokens().then(response => {
      return response.map(token => {
        const listedNft = nfts.listed.find(
          listed => listed.nft === token.address
        )
        return {
          ...token,
          price: listedNft ? listedNft.price : null,
        }
      })
    })
  })

  if (
    typeof window !== "undefined" &&
    window.location.search === "?verified=1"
  ) {
    toast.success(t("emailVerified"))
  }

  return (
    <Layout {...{ pageContext }}>
      <Section containerSize="large" padding="0 0 20px">
        <GridHero>
          <div>
            <StyledTitle>{t("index.contest.title")}</StyledTitle>
            <Subtitle>{t("index.contest.subtitle")}</Subtitle>
            <Spacer direction="row" align="center">
              {user.loggedIn ? (
                <Button
                  label={t("user.form.register.buttonGoToProfile")}
                  to={localizedPath({
                    defaultLang,
                    locale,
                    path: `/user/${user.publicKey}`,
                  })}
                />
              ) : (
                <Button
                  label={t("user.form.register.buttonCreateAccount")}
                  to={t("links:register.path")}
                />
              )}
              <StyledLink to={t("links:roadmap.path")}>
                {t("index.contest.roadmap")}
              </StyledLink>
            </Spacer>
          </div>
          <Box>
            <Fader
              slides={collections.map(
                ({
                  name,
                  description,
                  symbol,
                  likes_count,
                  vertical_teaser_url,
                  icon_url,
                  id,
                }) => {
                  return (
                    <HighlightTeaser
                      title="Highlights"
                      subtitle={t("collection.newCollection")}
                      name={name}
                      excerpt={description}
                      date={t("collection.newCollection")}
                      to={localizedPath({
                        defaultLang,
                        locale,
                        path: `/collection/${symbol}`,
                      })}
                      likeCount={likes_count}
                      image={vertical_teaser_url}
                      avatar={<Avatar image={icon_url} />}
                      key={id}
                    />
                  )
                }
              )}
            />
          </Box>
        </GridHero>
      </Section>
      <Section containerSize="large" padding="20px 0px">
        <Advantages>
          <Center>
            <TitleContainer>
              <Fees />
              <Title title="" subtitle={t("index.contest.fees")} />
            </TitleContainer>
            <p>{t("index.contest.feesText")}</p>
          </Center>
          <Center>
            <TitleContainer>
              <IntuitiveDesign />
              <Title title="" subtitle={t("index.contest.intuitiveDesign")} />
            </TitleContainer>
            <p>{t("index.contest.intuitiveDesignText")}</p>
          </Center>
          <Center>
            <TitleContainer>
              <Customer />
              <Title title="" subtitle={t("index.contest.customer")} />
            </TitleContainer>
            <p>{t("index.contest.customerText")}</p>
          </Center>
        </Advantages>
      </Section>
      <Section
        title={t("index.explore")}
        subtitle={t("index.exploreSubtitle")}
        containerSize="large"
      >
        <TopUsers />
      </Section>
      <Section
        title={t("index.latestPurchases")}
        subtitle={t("index.latestPurchasesSubtitle")}
        containerSize="large"
      >
        <Box style={{ marginTop: "40px" }} justify="start">
          {lastSoldTokensQuery.isLoading && (
            <Loader
              messages={[
                t("loading.theNft"),
                t("loading.itTakesLongerThanExpected"),
              ]}
              treshold={5}
            />
          )}
          {lastSoldTokensQuery.isSuccess && (
            <Grid responsive columns={4}>
              {lastSoldTokensQuery.data.slice(0, 4).map(token => (
                <NftTeaser
                  key={token.address}
                  to={localizedPath({
                    defaultLang,
                    locale,
                    path: `/nft/${token.address}/`,
                  })}
                  name={token.name}
                  likes={token.likes_count}
                  price={token.price}
                  image={
                    <Image
                      image={token.image}
                      type="NFT"
                      avatar={<Avatar image={token.image} />}
                    />
                  }
                />
              ))}
            </Grid>
          )}
        </Box>
      </Section>
      <Section containerSize="large" padding="20px 0px">
        <ArtistOfTheDay />
      </Section>
    </Layout>
  )
}
export default IndexPage

export const indexQuery = graphql`
  query GetCollections {
    allCollection(sort: { fields: created_at, order: DESC }) {
      nodes {
        address
        name
        id
        likes_count
        square_teaser_url
        vertical_teaser_url
        description
        icon_url
        symbol
      }
    }
  }
`
